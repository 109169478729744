import { graphql } from 'gatsby';
import React, { ReactElement } from 'react';
import Layout from '../components/Layout';
import PostList from '../components/PostList';
import { PostContent } from '../types/content';

interface Props {
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: {
        node: PostContent;
      }[];
    };
  };
}

const IndexPage = ({ data }: Props): ReactElement => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Layout>
      <PostList posts={posts} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            tags
          }
        }
      }
    }
  }
`;
